import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Typography, Grid, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import colors from '../../constants/colors';

const NewsInnerPage = ({ pageContext: { _news } }) => {
  return (
    <Layout>
      <SEO lang='en' title={_news.title} />
      <SectionContainerLayoutWithFilter title='NEWS' isViewAll baseLink='/news'>
        <Grid container>
          <Grid item sm={12} md={12}>
            <Typography variant='h5' className='text-uppercase'>
              {_news.title}
            </Typography>
          </Grid>
          <Grid item sm={12} md={12}>
            <Typography color='textSecondary' variant='h6'>
              {_news.date}
            </Typography>
          </Grid>
          <Grid item sm={12} md={12}>
            <Box mb={5} mt={5}>
              <img
                src={`${process.env.GATSBY_CMS_API_URL}/static/${_news.file.code}`}
                alt={_news.title}
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={12}>
            {/* <Typography paragraph color='textSecondary'>
              {_news.article}
            </Typography> */}
            <Box fontSize='body1.fontSize' style={{ color: colors.text.secondary.dark }}>
              <div dangerouslySetInnerHTML={{ __html: _news.article }} />
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default NewsInnerPage;
